@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.horizontalDivide {
  align-items: center;
  clear: both;
  display: none;

  &::before,
  &::after {
    border-top: {
      width: 1px;
      style: solid;
      color: $colour-oyster-grey;
    }
    content: '';
    display: block;
    flex: 1 0 auto;
    margin-top: .5em;

    &.white {
      color: $colour-white;
    }
  }

  &::before {
    margin-right: .5em;
  }

  &::after {
    margin-left: .5em;
  }
}

.fullWidth {
  margin-left: 0;
}
